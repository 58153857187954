import React, { Component } from 'react';
import { Row, Col, Button, Form, message, Spin } from 'antd';
import { LabelInput } from 'libs';
import { LangConsumer } from 'utils/LangProvider';
import { getFACData, getDefaultFACData, setFACData, updateFacInfo } from '../../store/views/WIFIConfig/action';
import { connect } from 'react-redux';
import { Check } from 'utils/commonCheck';
import './wifiConfig.less';

const mapStateToProps = (state) => {
    let { WIFIConfig, publicState, webCaps } = state;
    return { WIFIConfig, publicState, webCaps };
};

const mapDispatchToProps = {
    getFACData,
    getDefaultFACData,
    setFACData,
    updateFacInfo
};
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@LangConsumer
class FacInfoComponet extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false, // 数据获取之后渲染
        };
    }
    componentDidMount () {
        this.handleInit();
    }
    handleInit () {
        let {
            getFACData
        } = this.props;
        getFACData().then(() => {
            this.setState({
                isLoading: true
            });
        });
    }
    handelChange (name, e) {
        let {
            WIFIConfig: {
                FacInfo,
            },
            updateFacInfo
        } = this.props;
        let val = e instanceof Object ? e.target.value : e;
        if (/['"]+/.test(val) && !(['Phone', 'Email'].includes(name))) {
            return false;
        }
        switch (name) {
        case 'Name': //名称
            FacInfo.SecCompanyName = e.target.value;
            break;
        case 'SecCompanyCode': //组织结构代码
            FacInfo.SecCompanyCode = e.target.value;
            break;
        case 'SecCompanyAddr': //组织机构地址
            FacInfo.SecCompanyAddr = e.target.value;
            break;
        case 'SecCompanyContacts': //联系人
            FacInfo.SecCompanyContacts = e.target.value;
            break;
        case 'Phone': //电话号码
            FacInfo.Phone = e.target.value;
            break;
        case 'Email': //邮件
            FacInfo.Email = e.target.value;
            break;
        }
        updateFacInfo(FacInfo);
    }
    CheckEmail (value) {
        return Check.checkReg('email', value);
    }
    onBlur = () => {
        let {
            WIFIConfig: {
                FacInfo,
            },
            $t,
        } = this.props;
        //邮箱格式检查
        if (FacInfo.Email !== '' && !this.CheckEmail(FacInfo.Email)) {
            message.error($t('com.EmailFormatError'));
        }
    }
    onRefresh = () => {
        this.handleInit();
    }
    onDefault = () => {
        let {
            getDefaultFACData
        } = this.props;
        getDefaultFACData();
    }
    onSave = () => {
        let {
            WIFIConfig: {
                FacInfo,
            },
            $t,
            setFACData
        } = this.props;
        //邮箱格式检查
        if (FacInfo.Email !== '' && !this.CheckEmail(FacInfo.Email)) {
            message.error($t('com.EmailFormatError'));
        } else {
            setFACData(FacInfo);
        }
    }
    render () {
        let {
            WIFIConfig: {
                FacInfo
            },
            $t,
            publicState: {
                getGrid
            }
        } = this.props;
        const multiple = {
            type: 'normal',
            multiple: 1
        };
        if (this.state.isLoading) {
            return (
                <div className='_FacInfo_' style={{ padding: '20px 16px 16px 0px' }}>
                    <Form>
                        <Row>
                            <Col {...getGrid(multiple).container}>
                                <label className='labelText'>{$t('net.MacFactoryInfo')}</label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('com.Name')} value={FacInfo.SecCompanyName} onChange={this.handelChange.bind(this, 'Name')}/>
                            </Col>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('net.SecCompanyCode')} value={FacInfo.SecCompanyCode} onChange={this.handelChange.bind(this, 'SecCompanyCode')}/>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('net.SecCompanyAddr')} value={FacInfo.SecCompanyAddr} onChange={this.handelChange.bind(this, 'SecCompanyAddr')} />
                            </Col>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('net.SecCompanyContacts')} value={FacInfo.SecCompanyContacts} onChange={this.handelChange.bind(this, 'SecCompanyContacts')} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('pfm.Phone')} value={FacInfo.Phone} onChange={this.handelChange.bind(this, 'Phone')} />
                            </Col>
                            <Col span={12}>
                                <LabelInput type='text' colWidth={getGrid(multiple).colWidth} label={$t('net.Email')} value={FacInfo.Email} onChange={this.handelChange.bind(this, 'Email')} onBlur={this.onBlur} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col span={16} style={{ marginBottom: '16px', marginTop: '8px' }}>
                                <Col {...getGrid(multiple).label}></Col>
                                <Col>
                                    <Button onClick={() => this.onSave()} type='primary' className='fn-marr10 _submitButton'>{$t('com.Apply')}</Button>
                                    <Button onClick={() => this.onRefresh()} className='fn-marr10 _refreshButton'>{$t('com.Refresh')}</Button>
                                    <Button onClick={() => this.onDefault()} className="_defaultButton" >{$t('com.Comdefault')}</Button>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        } else {
            return (
                <div className='empty'>
                    <Spin size="large" />
                </div>
            );
        }
    }
}
const FacInfo = Form.create({})(FacInfoComponet);
export { FacInfo };